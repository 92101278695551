@font-face {
    font-family: SSP-Black;
    src: url(./fonts/SourceSansPro-Black.ttf)
}

@font-face {
    font-family: SSP-BlackItalic;
    src: url(./fonts/SourceSansPro-BlackItalic.ttf)
}

@font-face {
    font-family: SSP-Bold;
    src: url(./fonts/SourceSansPro-Bold.ttf)
}

@font-face {
    font-family: SSP-BoldItalic;
    src: url(./fonts/SourceSansPro-BoldItalic.ttf)
}

@font-face {
    font-family: SSP-ExtraLight;
    src: url(./fonts/SourceSansPro-ExtraLight.ttf)
}

@font-face {
    font-family: SSP-ExtraLightItalic;
    src: url(./fonts/SourceSansPro-ExtraLightItalic.ttf)
}

@font-face {
    font-family: SSP-Italic;
    src: url(./fonts/SourceSansPro-Italic.ttf)
}

@font-face {
    font-family: SSP-Regular;
    src: url(./fonts/SourceSansPro-Regular.ttf)
}

@font-face {
    font-family: SSP-SemiBold;
    src: url(./fonts/SourceSansPro-SemiBold.ttf)
}

@font-face {
    font-family: SSP-SemiBoldItalic;
    src: url(./fonts/SourceSansPro-SemiBoldItalic.ttf)
}

@font-face {
    font-family: SSP-Light;
    src: url(./fonts/SourceSansPro-Light.ttf)
}

@font-face {
    font-family: SSP-LightItalic;
    src: url(./fonts/SourceSansPro-LightItalic.ttf)
}